import React,{useEffect} from "react"
import './App.css';
import Navbar from './Navbar/Navbar';
import LandingPage from './LandingPage/LandingPage';
import InnerLayout from './InnerLayout/InnerLayout';
import Footer from './Footer/Footer';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';
import {ga} from "react-ga"
import TagManager from 'react-gtm-module'

function App() {

  useEffect(() => {
    ga('create', 'G-CTEXRFS4TK', 'auto');
    ga('send', 'pageview', window.location.pathname);
  }, []);

  const tagManagerArgs = {
    gtmId: 'GTM-TXDHQKR'
}

TagManager.initialize(tagManagerArgs)

  return (
    <div className="App">
      <Fade>
        <Navbar />
      </Fade>
      <LandingPage />
        
      <InnerLayout />
      <Footer />      

      <div className='whatsapp-chat-icon-container'>
        <div className='whatsapp-chat-icon'>
             <div>
             <a href="https://wa.me/+919088089789">
             <img className='whatsapp-chat' src="./images/whatsapp.svg" alt="" />
             </a>
             </div>
        
        </div>
        <Flash>
        <p>Chat Us</p>
        </Flash>
      </div>
    </div>
  );
}

export default App;
