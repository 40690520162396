import React from 'react';
import "./footer.css";


const Footer = () => {
  return (
    <div className='footer'>
        <p>Copywright © 2022 All Rights Reserved || Designed and Developed with 🤍 by <b>Solivia Business Solutions Pvt. Ltd.</b></p>
    </div>
  )
}

export default Footer